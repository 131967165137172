import React from 'react';
import './App.css'; // Custom CSS
import IosButton from './iOSButton';
import Navbar from './Navbar';

function Support() {
  return (
    <div className="continuous-scroll-container">
      <Navbar isSupportPage={true} />
      <section id="section1" className="section">
        <h1 className='mediumtitle'>Need a Hand? We've Got You Covered!</h1>
        <p>Whether it's setting up or keeping things secure, we're here to help you every step of the way.</p>
        <p>Start reading, to find the desired solution.</p>
      </section>
      <section id="section2" className="section">
        <h2>FAQs</h2>
        <p class="feature-text">
          Your Questions, Answered.
        </p>
        <br />
        <div className="feature-grid">
          <div className="feature-grid-rectangle">
            <h3>How do I sync my passwords across all devices?</h3>
            <br />
            <p className='feature-text-desc'>It's as easy as 1-2-3! Open YesPass on each device, sign in, and let the magic of seamless syncing do its thing.</p>
          </div>

          <div className="feature-grid-rectangle">
            <h3>How do I recover a lost password?</h3>
            <br />
            <p className='feature-text-desc'>Forgot your password? No worries. Head to our Password Recovery page, and we'll help you get back on track faster than you can say ‘YesPass.'</p>
          </div>

          <div className="feature-grid-rectangle">
            <h3>How secure is my data?</h3>
            <br />
            <p className='feature-text-desc'>Your security is our top priority. YesPass uses industry-leading encryption to make sure your data stays safe and sound – even we can't peek at it!</p>
          </div>


          <div className="feature-grid-rectangle">
            <h3>What should I do if the app isn't syncing?</h3>
            <br />
            <p className='feature-text-desc'>If your devices aren't syncing, it might just need a quick nudge. Follow our simple troubleshooting guide to get back in sync in no time!</p>
          </div>



        </div>

      </section>
      <section id="section3" className="section">
        <h2>Master YesPass in Minutes.</h2>
        <p class="feature-text">
        Want to take full control of your security? Our guides will have you up and running like a pro in no time!
        </p>
        <br />
        <div className="feature-grid">
          <div className="feature-grid-rectangle">
            <h3>Getting Started with YesPass - The Quick & Easy Way</h3>
            <br />
            <p className='feature-text-desc'>...</p>
          </div>

          <div className="feature-grid-rectangle">
            <h3>How to Sync Your Data Across Multiple Devices</h3>
            <br />
            <p className='feature-text-desc'>...</p>
          </div>

          <div className="feature-grid-rectangle">
            <h3>Top Tips for Supercharging Your Security</h3>
            <br />
            <p className='feature-text-desc'>...</p>
          </div>


          <div className="feature-grid-rectangle">
            <h3>....</h3>
            <br />
            <p className='feature-text-desc'>....</p>
          </div>


        </div>

      </section>
      <section id="section4" className="section">
        <h2>Still Stuck? We're Just a Click Away.</h2>
        <p class='feature-text'>
          Can't find what you're looking for? Don't worry, we're here to help.
        </p>
      </section>
      <section id="section5" className="section">
        <h2>Troubles? No Trouble!</h2>
        <p class='feature-text'>
        Running into a bump in the road? Let's smooth things out with these common fixes.
        </p>
      </section>
      <section id="section6" className="section">
        FAQs
      </section>
    </div>
  );
}
export default Support;