import React from 'react';
import './App.css'; // Custom CSS
import IosButton from './iOSButton';
import Navbar from './Navbar';

function Login() {
    return (
      <div className="continuous-scroll-container">
        <Navbar isSupportPage={false} />
        <section id="section1" className="section">
          <div className="login-container">
            <h2>Welcome Back!</h2>
            <form className="login-form">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input type="text" id="username" name="username" required />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" name="password" required />
              </div>
              <button type="submit">Login</button>
            </form>
          </div>
        </section>
      </div>
    );
  }
  
export default Login;