import React from 'react';
import './App.css'; // Custom CSS
import IosButton from './iOSButton';
import { IPhoneMockup } from 'react-device-mockup';
import gif from './login.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faShieldAlt, faSearch, faCloudUploadAlt, faDatabase, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Support from './Support'; // Your other component
import Mainapp from './Main';
import Login from './Login';
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">YesPass</Link>
      </div>
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/subpage">Features</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Mainapp />} />
          <Route path="/subpage" element={<Support />} />  {/* Subpage */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
